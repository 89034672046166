import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { ArrowDownIcon } from '@chakra-ui/icons';
import _ from 'lodash/fp';
import Confetti from 'react-dom-confetti';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';

import { IronhackLogo } from '../../icons/IronhackLogo';
import CohortCard from '../../containers/CohortCard';
import useUserLocation from '../../hooks/useUserLocation';
import { getFromStorage } from '../../storage/provider';
import { campusLookup, formatLookup } from '../../lib/utils';
import { getLinkByPageName } from '../../lib/links';
import { updateDataLayer } from '../../lib/datalayer';
import { getGaConnectorValues } from '../../lib/ga-connector';
import Cohorts from '../../components/interfaces/Cohorts';
import { courseToSalesforce } from '../../lib/salesforce-integration';
import Deindex from '../../components/Deindex';

type ShortCourseThanksProps = {
  pageContext: {
    pageName: string;
    courseCode: string;
    cohorts: Cohorts[];
  };
};

const DEFAULT_CAMPUS = 'rmt';
const APPLY_NOW_EVENT = (course: string) => ({
  event: 'gaEvent',
  eventCategory: 'interest',
  eventAction: 'click cta::apply',
  eventLabel: `${course.toUpperCase()}-Shortcourse::apply now`,
});
const MORE_INFO_EVENT = (course: string) => ({
  event: 'gaEvent',
  eventCategory: 'interest',
  eventAction: 'click cta',
  eventLabel: `${course.toUpperCase()}-Shortcourse::wd bootcamp information`,
});
const DOWNLOAD_SYLLABUS_EVENT = (course: string) => ({
  event: 'gaEvent',
  eventCategory: 'interest',
  eventAction: 'click cta',
  eventLabel: `${course.toUpperCase()}-Shortcourse::syllabus request`,
});

const CONFETTI_CONFIG = {
  angle: 90,
  dragFriction: 0.12,
  duration: 150000,
  elementCount: 250,
  height: '16px',
  perspective: '1000px',
  spread: 360,
  stagger: 25,
  startVelocity: 75,
  width: '4px',
};

type DownloadSyllabusProps = {
  courseCode: string;
  campus: string;
  showForm: boolean;
  setShowForm: (a: boolean) => void;
};

const DownloadSyllabusForm = (props): React.ReactElement => {
  const { defaultValues } = props;
  const { handleSubmit, register } = useForm({
    defaultValues,
  });
  const { t } = useTranslation();
  const { onSubmit } = props;

  const submitHandler = (data) => {
    onSubmit(
      _.merge(
        {
          email: data.email,
          Email_Opt_In: data.emailOptIn ? 1 : 0,
          sy_BootcampType: courseToSalesforce('webft'),
          sy_campus: data.campus,
        },
        getGaConnectorValues(document.cookie || '')
      )
    );
  };

  return (
    <>
      <Deindex />
      <Stack
        as="form"
        dir="column"
        onSubmit={handleSubmit(submitHandler)}
        spacing={3}
        w={['100%', null, 'auto']}
      >
        <FormControl isRequired minW="24.8rem">
          <FormLabel>
            {t('request-syllabus:requestSyllabus.fields.email')}
          </FormLabel>
          <Input
            borderColor="white"
            placeholder={t(
              'request-syllabus:requestSyllabus.placeholders.email'
            )}
            type="email"
            variant="flushed"
            {...register('email', { required: true })}
          />
        </FormControl>
        <FormControl>
          <Checkbox textAlign="left" {...register('emailOptIn')}>
            {t('request-syllabus:requestSyllabus.newsletter')}
          </Checkbox>
        </FormControl>
        <Button bg="white" type="submit" variant="outline">
          {t('request-syllabus:requestSyllabus.fields.button')}
        </Button>
      </Stack>
    </>
  );
};

const courseCodeMap = {
  ux: 'uxui',
  js: 'web',
};

const DownloadSyllabus = (props: DownloadSyllabusProps): React.ReactElement => {
  const { campus, courseCode, showForm, setShowForm } = props;
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();

  const onSubmit = (data): Promise<any> => {
    const baseUrl = process.env.IRONHACK_API?.slice(0, -8) as string;

    return fetch(
      `${baseUrl}/formHandlerSubmission/shortCourseSyllabusRequest`,
      {
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      }
    )
      .then(() => {
        updateDataLayer(DOWNLOAD_SYLLABUS_EVENT(courseCode));
        setSubmitted(true);
        setTimeout((): void => {
          setSubmitted(false);
        }, 5000);
      })
      .catch((error) => {
        // doing nothing with error - just moving forward
        console.error('ERROR:', error);
      });
  };

  return (
    <>
      <Box
        as={Collapse}
        bg="whiteAlpha.5"
        borderRadius="xl"
        boxShadow="3xl"
        in={showForm}
        maxW="80ch"
        mb={2}
        px={[2, null, 4]}
        py={4}
      >
        <Text mb={4} textStyle="body1">
          {t('short-courses:shortCourses.congratulations.syllabusCTA')}
        </Text>
        <DownloadSyllabusForm
          defaultValues={{
            campus,
            email: location?.state?.email,
            emailOptIn: null,
          }}
          onSubmit={onSubmit}
        />
        <Collapse in={submitted}>
          <Text mt={2} textStyle="body4">
            {t('request-syllabus:requestSyllabus.okayMessage')}
          </Text>
        </Collapse>
      </Box>
      {!showForm && (
        <Button
          bg="white"
          onClick={(): void => setShowForm(true)}
          variant="outline"
        >
          {t('short-courses:shortCourses.congratulations.syllabusCTA')}
        </Button>
      )}
    </>
  );
};

const courseUrlMap = {
  js: 'web-development',
  ux: 'ux-ui-design',
};

const ShortCourseThanks = (
  props: ShortCourseThanksProps
): React.ReactElement => {
  const { pageContext } = props;
  const { courseCode } = pageContext;
  const { i18n, t } = useTranslation();
  const [cohorts, setCohorts] = useState(pageContext.cohorts.slice(0, 4));
  const [confettiTime, setConfettiTime] = useState<boolean>(false);
  const [showSyllabusForm, setShowSyllabusForm] = useState<boolean>(false);
  const [campus, setCampus] = useState(DEFAULT_CAMPUS);
  const { isLoading } = useUserLocation();

  useEffect(() => {
    setConfettiTime(true);
  }, [setConfettiTime]);

  useEffect(() => {
    if (!isLoading) {
      const { campusVisited, defaultCampus } = getFromStorage([
        'campusVisited',
        'defaultCampus',
      ]);
      const storedCampus = (campusVisited ||
        defaultCampus ||
        DEFAULT_CAMPUS) as string;
      const filteredCohorts = pageContext.cohorts.filter(
        (c) =>
          c.campus_code === storedCampus &&
          c.course_code.startsWith(courseCodeMap[courseCode])
      );
      setCohorts(filteredCohorts);
      setCampus(storedCampus);
    }
  }, [setCampus, setCohorts, isLoading, pageContext.cohorts]);

  const formatGroupedCohorts = _.groupBy(
    (c) => formatLookup(c.course_code.slice(-2)),
    cohorts
  );
  const cohortFormats = _.intersection(Object.keys(formatGroupedCohorts), [
    'fullTime',
    'partTime',
  ]);

  return (
    <>
      <Center bg="darkBlue.100" overflow="hidden" pb={[3]} pt={[4, null, 10]}>
        <Box
          active={confettiTime}
          as={Confetti}
          config={CONFETTI_CONFIG}
          left="25%"
          pos="absolute"
        />
        <Box
          as={Link}
          left={['2.4rem', null, '10rem']}
          pos="absolute"
          to={getLinkByPageName('home')}
          top="1.6rem"
        >
          <IronhackLogo boxSize={['3.2rem', null, '4.8rem']} color="white" />
        </Box>
        <Box color="white" mx={3} textAlign="center">
          <Text color="white" mb={[2]} textStyle="jumbo">
            {t('short-courses:shortCourses.congratulations.title')}
          </Text>
          <Text color="whiteAlpha.80" fontWeight="semibold" textStyle="body2">
            {t('short-courses:shortCourses.congratulations.subtitle')}
          </Text>
          <Stack
            as={Center}
            direction={['column', null, showSyllabusForm ? 'column' : 'row']}
            my={[3, null, 4]}
            spacing={2}
          >
            <DownloadSyllabus
              campus={campus}
              courseCode={courseCode}
              setShowForm={setShowSyllabusForm}
              showForm={showSyllabusForm}
            />
            <Button
              as={Link}
              onClick={(): void =>
                updateDataLayer({ ...MORE_INFO_EVENT(courseCode), campus })
              }
              to={`/${i18n.language}/${courseUrlMap[courseCode]}/${campusLookup(
                campus
              )?.toLowerCase()}`}
            >
              {t('short-courses:shortCourses.congratulations.moreInfoCTA')}
            </Button>
          </Stack>
          <Text fontWeight="bold" textStyle="body3">
            {t('short-courses:shortCourses.congratulations.applyCTA')}
          </Text>
          <ArrowDownIcon boxSize="1.6rem" mt={[1]} />
        </Box>
      </Center>
      <Container
        as={Skeleton}
        isLoaded={!isLoading}
        maxW="144rem"
        pl={[0, null, 9]}
      >
        <Tabs mt={[4, null, 2]}>
          <TabList>
            {cohortFormats.map((format) => (
              <Tab key={`${format}-tab`}>
                <Text mr={[0.5, null, 1]} textStyle={['body2', null, 'body1']}>
                  {t(
                    `course-campus:courseCampus.experiment.formats.${format}.title`
                  )}
                </Text>
                <Text color="darkBlue.40" textStyle={['body6', null, 'body4']}>
                  {'('}
                  {t(`course-campus:courseCampus.formats.${format}.weeks`)}
                  {')'}
                </Text>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {cohortFormats.map((format) => (
              <TabPanel
                as={HStack}
                key={`${format}-panel`}
                overflowX="scroll"
                pl={5}
                py={5}
                spacing={3}
              >
                {formatGroupedCohorts[format].map((cohort) => (
                  <CohortCard
                    applyCta={{
                      onClick: (): void =>
                        updateDataLayer(APPLY_NOW_EVENT(courseCode)),
                    }}
                    applySource={pageContext.pageName}
                    cohort={cohort}
                    financingOptions={{ onClick: (): void => {} }}
                    key={cohort.id}
                  />
                ))}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
};

export const query = graphql`
  query ($locale: String!, $defaultLanguage: String!) {
    locales: allLocale(
      filter: {
        lng: { in: [$locale, $defaultLanguage] }
        ns: {
          regex: "/(seo|menu|common-dates|covid|course-campus|short-courses|request-syllabus|languages|upcoming-cohorts)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default ShortCourseThanks;
